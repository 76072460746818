import React, { PropsWithChildren, useCallback, useRef, useState } from "react";

import { Button } from "@/components/ui/button";

import Modal, { ModalFooter } from "../../../common/Modal";

import "./styles.scss";

export type OpportunityVersionCommentModalProps = {
  onClose: () => void;
  onConfirm: (comment?: string) => void;
};

const OpportunityVersionCommentModal = ({
  onClose,
  onConfirm,
}: PropsWithChildren<OpportunityVersionCommentModalProps>) => {
  const [comment, setComment] = useState("");

  const commentFieldRef = useRef<HTMLTextAreaElement>(null);
  const focusCommentField = () => {
    if (commentFieldRef.current) {
      (commentFieldRef.current as HTMLTextAreaElement).focus();
    }
  };

  React.useEffect(focusCommentField, [commentFieldRef]);

  const _onConfirm = useCallback(() => {
    if (comment.trim() === "") {
      onConfirm();
    } else {
      onConfirm(comment);
    }
  }, [onConfirm, comment]);

  return (
    <Modal
      title={"What did you change?"}
      width={400}
      contentHeight={400}
      onClose={onClose}
    >
      <div className="component scenario-name">
        <div className="header relative m-3">
          <textarea
            className="search-input w-full pl-1 py-1 border rounded-md bg-gray-100 text-sm h-24"
            ref={commentFieldRef}
            onChange={(field) => setComment(field.target.value)}
            placeholder="Describe what you updated here..."
            value={comment}
          />
        </div>
        <ModalFooter className="flex gap-4 justify-end">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={_onConfirm}>Save</Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default OpportunityVersionCommentModal;
