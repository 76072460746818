import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Decimal from "decimal.js-light";
import moment from "moment/moment";
import { map } from "ramda";

import {
  useDeleteOpportunityMutation,
  useGetOpportunityQuery,
  useSaveOpportunityMutation,
} from "@pricing-tool/graphql/lib/react";

import {
  fromOpportunity,
  OpportunityModel,
} from "@pricing-tool/lib/opportunity/model";
import { Opportunity as OpportunityType } from "@pricing-tool/lib/opportunity/core";
import {
  fromDto as fromOpportunityDto,
  OpportunityDto,
  toInput as toOpportunityInput,
} from "@pricing-tool/lib/opportunity/dto";

import elements from "@pricing-tool/lib/products/treasury/fixtures/elements.json";
import { fromDto as parseElement } from "@pricing-tool/lib/products/treasury/core/Element";

import packages from "@pricing-tool/lib/products/treasury/fixtures/packages.json";
import { parse as parsePackage } from "@pricing-tool/lib/products/treasury/core/Package";
import { ScenarioDependencies } from "@pricing-tool/lib/scenario/model";
import collateralRecoveryTable from "@pricing-tool/lib/products/ci/fixtures/collateralRecoveryTable";

import {
  fromForwardCurvesDto,
  fromFundingCurveDto,
  fromIndexRatesDto,
} from "@pricing-tool/lib/products/ci/core/dto";
import { FundingCurve } from "@pricing-tool/lib/products/ci/core/FundingCurve";
import { ForwardCurves } from "@pricing-tool/lib/products/ci/core/ForwardCurves";
import { IndexRate } from "@pricing-tool/lib/products/ci/core/IndexRates";
import forwardCurve from "@pricing-tool/lib/products/ci/fixtures/forwardCurve";

import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";

import OpportunityEditor from "../../../components/OpportunityEditor";
import LoadingOverlay from "../../../components/common/LoadingOverlay";
import useOpportunityModel from "../../../hooks/useOpportunityModel";
import ErrorBanner from "../../../components/common/ErrorBanner";
import RightDock from "@/src/components/opportunity/RightDock";

const dependencies: ScenarioDependencies = {
  taxRate: new Decimal(0.21),
  minimumCapital: new Decimal(0.09),
  minimumLoanLoss: new Decimal(0.0015),
  latestForwardCurves: forwardCurve,
  ecrEligibleRatio: new Decimal(0.8),
  fundTransferPricingRate: new Decimal(0.03),
  liquidityTransferPricingRate: new Decimal(0),
  annualizedEcr: new Decimal(0.009),
  annualizedInterestRate: new Decimal(0.012),
  annualEcrEligibleCharges: new Decimal(5000),
  reserveRequirements: new Decimal(0.1),
};

// FIXME - don't hardcode
const performanceTargets = {
  returnOnEquity: new Decimal("0.1500"),
  returnOnAssets: new Decimal("0.0100"),
};

const elementCatalog = map(parseElement, elements);
const packageCatalog = map(parsePackage, packages);

function Component(props: {
  opportunityModel: OpportunityModel;
  latestIndexRates: IndexRate[];
  latestFundingCurve: FundingCurve;
  latestForwardCurves: ForwardCurves;
  onSaveOpportunity: (opportunity: OpportunityType, comment?: string) => void;
  onDeleteOpportunity: () => void;
}) {
  const { opportunity } = useOpportunityModel(props.opportunityModel);

  if (!opportunity) return null;

  return (
    <OpportunityEditor
      opportunityModel={props.opportunityModel}
      dependencies={{
        ...dependencies,
        latestIndexRates: props.latestIndexRates,
        latestFundingCurve: props.latestFundingCurve,
        latestForwardCurves: props.latestForwardCurves,
        startDate: moment(opportunity.closeDate).toDate(),
      }}
      performanceTargets={performanceTargets}
      elementCatalog={elementCatalog}
      packageCatalog={packageCatalog}
      collateralRecoveryTable={collateralRecoveryTable}
      onSaveOpportunity={(comment) =>
        props.onSaveOpportunity(opportunity, comment)
      }
      onDeleteOpportunity={props.onDeleteOpportunity}
    />
  );
}

function Opportunity() {
  const { opportunityId } = useParams();
  const navigate = useNavigate();

  const [opportunityModel, setOpportunityModel] = useState<
    OpportunityModel | undefined
  >();
  const [latestIndexRates, setLatestIndexRates] = useState<
    IndexRate[] | undefined
  >();
  const [latestFundingCurve, setLatestFundingCurve] = useState<
    FundingCurve | undefined
  >();
  const [latestForwardCurves, setLatestForwardCurves] = useState<
    ForwardCurves | undefined
  >();

  const { loading, error } = useGetOpportunityQuery({
    variables: { opportunityId: opportunityId!, branchId: "branch-1" },
    onCompleted: (data) => {
      if (
        !data ||
        !data.getOpportunity ||
        !data.latestIndexRates ||
        !data.latestFundingCurve ||
        !data.latestForwardCurves
      )
        return;

      const opportunityDto: OpportunityDto =
        data.getOpportunity as OpportunityDto;
      const opportunity = fromOpportunityDto(opportunityDto);

      const latestIndexRatesDto = data.latestIndexRates;
      const latestIndexRates = fromIndexRatesDto(latestIndexRatesDto as any);
      setLatestIndexRates(latestIndexRates);

      const latestFundingCurveDto = data.latestFundingCurve;
      const latestFundingCurve = fromFundingCurveDto(
        latestFundingCurveDto as any,
      );
      setLatestFundingCurve(latestFundingCurve);

      const latestForwardCurvesDto = data.latestForwardCurves;
      const latestForwardCurves = fromForwardCurvesDto(latestForwardCurvesDto);
      setLatestForwardCurves(latestForwardCurves);

      const constructedOpportunityModel = fromOpportunity(opportunity, {
        ...dependencies,
        latestIndexRates,
        latestFundingCurve,
      });
      setOpportunityModel(constructedOpportunityModel);
    },
  });

  const [saveOpportunity, { loading: saving, error: saveError }] =
    useSaveOpportunityMutation();

  const [deleteOpportunity, { loading: deleting, error: deleteError }] =
    useDeleteOpportunityMutation();

  const onSaveOpportunity = useCallback(
    (opportunity: OpportunityType, comment?: string) => {
      const opportunityInput = toOpportunityInput(opportunity);
      opportunityInput.annotation = comment;
      saveOpportunity({
        variables: {
          branchId: "branch-1",
          input: opportunityInput,
        },
        onCompleted: (data) => {
          if (!data || !data.saveOpportunity) return;

          // reload the page
          // FIXME - figure out how to "reload" the opportunityModel in downstream components
          navigate(0);
        },
      }).then(() => console.log("saved opportunity"));
    },
    [saveOpportunity, navigate],
  );

  const onDeleteOpportunity = useCallback(() => {
    void deleteOpportunity({
      variables: {
        branchId: "branch-1",
        opportunityId: opportunityId!,
      },
      update: (cache) => {
        cache.evict({ id: "Opportunity:" + opportunityId! });
      },
      onCompleted: () => {
        navigate("/opportunities");
      },
    });
  }, [deleteOpportunity, opportunityId, navigate]);

  return (
    <>
      {loading && <LoadingOverlay />}
      {saving && <LoadingOverlay text="Saving..." />}
      {deleting && <LoadingOverlay text="Deleting..." />}

      <SidebarProvider
        defaultOpen={false}
        style={
          {
            "--sidebar-width": "350px",
          } as React.CSSProperties
        }
        className=""
      >
        <SidebarInset className="overflow-y-auto h-[calc(100svh-3rem)]">
          {opportunityModel && (
            <Component
              opportunityModel={opportunityModel}
              latestIndexRates={latestIndexRates!}
              latestFundingCurve={latestFundingCurve!}
              latestForwardCurves={latestForwardCurves!}
              onSaveOpportunity={onSaveOpportunity}
              onDeleteOpportunity={onDeleteOpportunity}
            />
          )}
        </SidebarInset>
        <RightDock branchId={"branch-1"} opportunityId={opportunityId!} />
      </SidebarProvider>

      {error && <ErrorBanner text={error.message} />}
      {saveError && <ErrorBanner text={saveError.message} />}
      {deleteError && <ErrorBanner text={deleteError.message} />}
    </>
  );
}

export default Opportunity;
