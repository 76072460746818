import React from "react";
import Decimal from "decimal.js-light";
import { useLocation, useNavigate, useParams } from "react-router";

import { useGetOpportunityVersionsQuery } from "@pricing-tool/graphql/lib/react";

import { fromDto } from "@pricing-tool/lib/opportunityVersionSummary/dto";
import { OpportunityVersionSummary } from "@pricing-tool/lib/opportunityVersionSummary/core";

import { ModalFooter } from "@/src/components/common/Modal";
import { Button } from "@/components/ui/button";
import HistoryTable from "@/src/components/history/tables/HistoryTable";
import { useApolloClient } from "@apollo/client";

const performanceTargets = {
  returnOnEquity: new Decimal("0.1500"),
  returnOnAssets: new Decimal("0.0100"),
};

function History() {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const location = useLocation();
  const apolloClient = useApolloClient();

  const [displayResults, setDisplayResults] = React.useState<
    OpportunityVersionSummary[] | undefined
  >(undefined);

  let state = location.state as { backgroundLocation?: Location } | null;
  let renderedInModal = !!state?.backgroundLocation;

  console.log("cache before", apolloClient.cache.extract());

  const { loading, error } = useGetOpportunityVersionsQuery({
    variables: {
      branchId: "branch-1",
      opportunityId: opportunityId!,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log("data", data);
      console.log("cache after", apolloClient.cache.extract());
      const displayResults = data.getOpportunityVersions.map(fromDto);
      setDisplayResults(displayResults);
    },
  });

  return (
    <div className="history">
      <div className="body overflow-auto p-4">
        {!renderedInModal && <div className="text-2xl mb-4">History</div>}

        {loading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}

        {displayResults && (
          <HistoryTable
            versions={displayResults}
            performanceTargets={performanceTargets}
          />
        )}
      </div>
      {renderedInModal && (
        <ModalFooter className="flex gap-4 justify-end">
          <Button
            variant="secondary"
            onClick={() => navigate(state?.backgroundLocation!)}
          >
            Close
          </Button>
        </ModalFooter>
      )}
    </div>
  );
}

export default History;
