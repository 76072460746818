import React from "react";

import Product from "../Product";
import { ProductsProps } from "./props";
import { Button } from "@/components/ui/button";
import { PlusCircle } from "lucide-react";
import { Separator } from "@radix-ui/react-select";

const Component = ({
  products,
  selectedProduct,
  onAddProduct,
  onSelectProduct,
  onRemoveProduct,
}: ProductsProps) => {
  return (
    <div className="py-2 px-4">
      <div className="uppercase text-gray-400 text-xs font-semibold">
        Products
      </div>
      <div className="flex flex-col items-start my-2 rounded-lg border-gray-300 border overflow-hidden w-fit">
        {products.map((product, i) => (
          <div key={product.name}>
            <Product
              product={product}
              selected={selectedProduct === product.productType}
              selectProduct={() => onSelectProduct(product.productType)}
              removeProduct={
                onRemoveProduct && (() => onRemoveProduct(product.productType))
              }
            />
            <Separator className="h-[1px] bg-gray-300" />
          </div>
        ))}
        {onAddProduct && (
          <div className="w-full">
            <Button
              onClick={onAddProduct}
              variant="ghost"
              className="w-full text-gray-400 hover:text-gray-600"
            >
              <div className="flex gap-1 items-center">
                <PlusCircle />
                Add Product
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Component;
