import React, { useCallback, useEffect, useMemo } from "react";
import { find, map } from "ramda";
import { ProductType } from "@pricing-tool/lib/products/core";

import Products from "../opportunity/common/Products";
import {
  ProductSummary,
  toProductSummary,
} from "../OpportunityEditor/utils/ProductSummary";

import { ScenarioViewerProps } from "./props";

import DepositsViewer from "@/src/components/deposits/fields/DepositsViewer";
import { Deposits } from "@pricing-tool/lib/products/deposits/core";
import CommercialIndustrialViewer from "@/src/components/ci/fields/CommercialIndustrialViewer";
import { CommercialIndustrial } from "@pricing-tool/lib/products/ci/core";
import CommercialRealEstateViewer from "@/src/components/cre/fields/CommercialRealEstateViewer";
import { CommercialRealEstate } from "@pricing-tool/lib/products/cre/core";

import "./styles.scss";
import TreasuryViewer from "@/src/components/treasury/fields/TreasuryViewer";
import { Treasury } from "@pricing-tool/lib/products/treasury/core";

const ScenarioViewer = ({
  scenario,
  selectedProduct,
  onSelectProduct,
}: ScenarioViewerProps) => {
  const { products } = scenario;

  useEffect(() => {
    if (!products || products.length === 0) return;
    if (selectedProduct) return;

    onSelectProduct(scenario.id, products[0]._type);
  }, [products]);

  const productSummaries: ProductSummary[] = useMemo(
    () => map(toProductSummary, scenario?.products || []),
    [scenario],
  );

  const selectProduct = useCallback(
    (productType: ProductType) => {
      if (!products) return;
      const product = find(
        (product) => product._type === productType,
        products,
      );

      if (!product) return;
      onSelectProduct(scenario.id, product._type);
    },
    [products],
  );

  useEffect(() => {
    if (!selectedProduct) return;
    onSelectProduct(scenario.id, selectedProduct);
  }, [selectedProduct]); // eslint-disable-line react-hooks/exhaustive-deps

  const product = useMemo(() => {
    if (!selectedProduct) return;
    return find((product) => product._type === selectedProduct, products);
  }, [selectedProduct, products]);

  if (!scenario) {
    return <></>;
  }

  return (
    <>
      <div className="flex">
        <Products
          products={productSummaries}
          selectedProduct={selectedProduct}
          onSelectProduct={selectProduct}
        />
        <div className="p-4 w-full">
          {selectedProduct && selectedProduct === "treasury" && (
            <TreasuryViewer treasury={product as Treasury} />
          )}

          {selectedProduct && selectedProduct === "ci" && (
            <CommercialIndustrialViewer
              commercialIndustrial={product as CommercialIndustrial}
            />
          )}

          {selectedProduct && selectedProduct === "deposits" && (
            <DepositsViewer deposits={product as Deposits} />
          )}

          {selectedProduct && selectedProduct === "cre" && (
            <CommercialRealEstateViewer
              commercialRealEstate={product as CommercialRealEstate}
            />
          )}

          {/*<pre className="text-gray-50">*/}
          {/*  {scenario && JSON.stringify(scenario, null, 2)}*/}
          {/*</pre>*/}
        </div>
      </div>
    </>
  );
};

export default ScenarioViewer;
