import React from "react";
import { CommercialRealEstate } from "@pricing-tool/lib/products/cre/core";
import LoanViewer from "@/src/components/common/LoanViewer";

export type CommercialRealEstateFormProps = {
  commercialRealEstate: CommercialRealEstate;
};

const CommercialRealEstateViewer = ({
  commercialRealEstate,
}: CommercialRealEstateFormProps) => {
  if (!commercialRealEstate) {
    return <></>;
  }

  return <LoanViewer product={commercialRealEstate} />;
};

export default CommercialRealEstateViewer;
