import React, { useCallback, useMemo, useState } from "react";
import { map } from "ramda";

import { Scenario } from "@pricing-tool/lib/scenario/core";

import Scenarios from "./component";
import ScenarioNameModal from "../../../common/ScenarioNameModal";
import ScenariosPicker from "../../../OpportunityEditor/components/ScenariosPicker";
import {
  ScenarioSummary,
  toScenarioSummary,
} from "../../../OpportunityEditor/utils/ScenarioSummary";
import NewScenarioModal from "../../../common/NewScenarioModal";
import { PerformanceTargets } from "../../../../utils/constructPerformance";

export type ScenariosProps = {
  opportunityId: string;
  scenarios: Scenario[];
  performanceTargets: PerformanceTargets;
  selectedScenarioId?: string;
  pipelineScenarioId?: string;
  onAddScenario?: (name: string) => void;
  onCloneScenarioClicked?: () => void;
  onSelectScenario: (id: string) => void;
  onRemoveScenario?: (id: string) => void;
  onRenameScenario?: (id: string, name: string) => void;
  onDuplicateScenario?: (id: string, name: string) => void;
  onCompareScenario: (id: string, comparisonScenarioIds: string[]) => void;
  onMakePipelineScenario?: (id: string) => void;
  onCreateDocument?: () => void;
};

const Component = ({
  opportunityId,
  scenarios,
  performanceTargets,
  onAddScenario,
  onCloneScenarioClicked,
  selectedScenarioId,
  pipelineScenarioId,
  onSelectScenario,
  onRemoveScenario,
  onRenameScenario,
  onDuplicateScenario,
  onCompareScenario,
  onMakePipelineScenario,
  onCreateDocument,
}: ScenariosProps) => {
  const [showScenarioNameModal, setShowScenarioNameModal] = useState(false);
  const [showNewScenarioModal, setShowNewScenarioModal] = useState(false);
  const [comparisonScenarioId, setComparisonScenarioId] = useState<
    string | undefined
  >();

  const _onAddScenario = useCallback(
    (name: string) => {
      setShowNewScenarioModal(false);
      onAddScenario(name);
    },
    [onAddScenario],
  );

  const scenarioSummaries: ScenarioSummary[] = useMemo(() => {
    return map(
      (scenario) =>
        toScenarioSummary(scenario, performanceTargets, selectedScenarioId),
      scenarios || [],
    );
  }, [selectedScenarioId, scenarios]);

  return (
    <>
      <Scenarios
        opportunityId={opportunityId}
        scenarios={scenarioSummaries}
        pipelineScenarioId={pipelineScenarioId}
        onAddScenario={onAddScenario && (() => setShowNewScenarioModal(true))}
        onSelectScenario={onSelectScenario}
        onRemoveScenario={onRemoveScenario}
        onRenameScenario={onRenameScenario}
        onDuplicateScenario={onDuplicateScenario}
        onCompareScenarioWith={setComparisonScenarioId}
        onMakePipelineScenario={onMakePipelineScenario}
        onCreateDocument={onCreateDocument}
      />

      {showScenarioNameModal && (
        <ScenarioNameModal
          onClose={() => setShowScenarioNameModal(false)}
          onConfirm={_onAddScenario}
        />
      )}

      {showNewScenarioModal && (
        <NewScenarioModal
          onClose={() => setShowNewScenarioModal(false)}
          onCloneScenarioClicked={() => {
            setShowNewScenarioModal(false);
            onCloneScenarioClicked();
          }}
          onConfirm={_onAddScenario}
        />
      )}

      {comparisonScenarioId && (
        <ScenariosPicker
          scenarios={scenarioSummaries}
          selectedScenarioId={comparisonScenarioId}
          onConfirm={(comparisonScenarioIds) => {
            const id = comparisonScenarioId;
            setComparisonScenarioId(undefined);
            onCompareScenario(id, comparisonScenarioIds);
          }}
          onCancel={() => setComparisonScenarioId(undefined)}
        />
      )}
    </>
  );
};

export default Component;
