import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useObservableState } from "observable-hooks";
import { find, findIndex, indexOf, isNil, map, pluck, reject } from "ramda";

import { asObservable, ProductModel } from "@pricing-tool/lib/scenario/model";
import { CommercialIndustrialDependencies } from "@pricing-tool/lib/products/ci/core";
import { Product, ProductType } from "@pricing-tool/lib/products/core";
import { fromProduct } from "@pricing-tool/lib/products/model";

import Products from "../opportunity/common/Products";
import TreasuryForm from "../treasury/forms/TreasuryForm";
import ProductPicker from "../opportunitiy/common/ProductPicker";
import CommercialIndustrialForm from "../ci/forms/CommercialIndustrialForm";
import {
  ProductSummary,
  toProductSummary,
} from "../OpportunityEditor/utils/ProductSummary";
import DepositsForm from "../deposits/forms/DepositsForm";
import CommercialRealEstateForm from "../cre/forms/CommercialRealEstateForm";

import { ScenarioEditorProps } from "./props";
import "./styles.scss";

const ScenarioEditor = ({
  scenarioModel,
  selectedProduct,
  dependencies,
  elementCatalog,
  packageCatalog,
  collateralRecoveryTable,
  ...props
}: ScenarioEditorProps) => {
  const scenario$ = asObservable(scenarioModel);
  const productModels = useObservableState(scenarioModel.input.products);
  const [scenario] = useObservableState(() => scenario$);

  const [selectedProductModel, setSelectedProductModel] = useState<
    ProductModel | undefined
  >();
  const [showProductPicker, setShowProductPicker] = useState(false);

  useEffect(() => {
    if (!productModels || productModels.length === 0) return;
    if (selectedProductModel) return;

    setSelectedProductModel(productModels[0]);
  }, [productModels, selectedProductModel]);

  const addedProducts = useMemo(() => {
    if (!scenario) return [];
    return pluck("_type", scenario.products);
  }, [scenario]);

  const products: ProductSummary[] = useMemo(
    () => map(toProductSummary, scenario?.products || []),
    [scenario],
  );

  const selectProduct = useCallback(
    (product: ProductType) => {
      if (!productModels) return;
      const productModel = find(
        (productModel) => productModel._type === product,
        productModels,
      );

      if (!productModel) return;
      setSelectedProductModel(productModel);
    },
    [productModels],
  );

  useEffect(() => {
    if (!selectedProduct) return;

    const productModels$ = scenarioModel.input.products;
    const productModels = productModels$.getValue();
    const selectedProductModel = find(
      (productModel) => productModel._type === selectedProduct,
      productModels,
    );

    setSelectedProductModel(selectedProductModel);
  }, [scenarioModel.input.products, selectedProduct]);

  useEffect(() => {
    if (!selectedProductModel) return;
    props.onSelectProduct(scenarioModel.input.id, selectedProductModel._type);
  }, [selectedProductModel]); // eslint-disable-line react-hooks/exhaustive-deps

  const addProductClicked = () => setShowProductPicker(true);
  const closeProductPicker = () => setShowProductPicker(false);

  const addProduct = useCallback(
    (product: ProductType) => {
      const newModel = fromProduct({ _type: product } as Product, dependencies);

      const productModels$ = scenarioModel.input.products;
      const productModels = productModels$.getValue();
      setSelectedProductModel(newModel);
      closeProductPicker();

      productModels$.next([...productModels, newModel]);
    },
    [scenarioModel.input.products, dependencies],
  );

  const removeProduct = useCallback(
    (product: ProductType) => {
      const productModels$ = scenarioModel.input.products;
      const productModels = productModels$.getValue();
      const productFilter = (productModel: ProductModel) =>
        productModel._type === product;
      const indexToRemove = findIndex(productFilter, productModels);
      const currentIndex = indexOf(selectedProductModel, productModels);
      const newProductModels =
        reject<ProductModel>(productFilter)(productModels);

      let newIndex: number | undefined = currentIndex;
      if (indexToRemove <= currentIndex) {
        newIndex = currentIndex - 1;
      }
      if (newIndex < 0) {
        newIndex = newProductModels.length ? 0 : undefined;
      }
      const newSelectedProductModel = !isNil(newIndex)
        ? newProductModels[newIndex]
        : undefined;
      setSelectedProductModel(newSelectedProductModel);

      productModels$.next(newProductModels);
    },
    [scenarioModel.input.products, selectedProductModel],
  );

  const _onCloneProductFromThisOpportunityClicked = () => {
    setShowProductPicker(false);
    props.onCloneProductFromThisOpportunityClicked();
  };

  const _onCloneProductFromOtherOpportunityClicked = () => {
    setShowProductPicker(false);
    props.onCloneProductFromOtherOpportunityClicked();
  };

  if (!scenario) {
    return <></>;
  }

  return (
    <>
      <div className="flex">
        <Products
          products={products}
          selectedProduct={selectedProduct}
          onAddProduct={addProductClicked}
          onSelectProduct={selectProduct}
          onRemoveProduct={removeProduct}
        />
        <div className="p-4 w-full">
          {selectedProductModel &&
            selectedProductModel._type === "treasury" && (
              <TreasuryForm
                treasuryModel={selectedProductModel}
                elementCatalog={elementCatalog}
                packageCatalog={packageCatalog}
              />
            )}

          {selectedProductModel && selectedProductModel._type === "ci" && (
            <CommercialIndustrialForm
              commercialIndustrialModel={selectedProductModel}
              dependencies={dependencies as CommercialIndustrialDependencies}
              collateralRecoveryTable={collateralRecoveryTable}
            />
          )}

          {selectedProductModel &&
            selectedProductModel._type === "deposits" && (
              <DepositsForm depositsModel={selectedProductModel} />
            )}

          {selectedProductModel && selectedProductModel._type === "cre" && (
            <CommercialRealEstateForm
              commercialRealEstateModel={selectedProductModel}
              dependencies={dependencies as CommercialIndustrialDependencies}
              collateralRecoveryTable={collateralRecoveryTable}
            />
          )}

          {/*<pre className="text-gray-50">*/}
          {/*  {scenario && JSON.stringify(scenario, null, 2)}*/}
          {/*</pre>*/}
        </div>
      </div>

      {showProductPicker && (
        <ProductPicker
          addedProducts={addedProducts}
          onSelect={addProduct}
          onCancel={closeProductPicker}
          onCloneFromThisOpportunityClicked={
            _onCloneProductFromThisOpportunityClicked
          }
          onCloneFromOtherOpportunityClicked={
            _onCloneProductFromOtherOpportunityClicked
          }
        />
      )}
    </>
  );
};

export default ScenarioEditor;
