import React from "react";

import { Treasury } from "@pricing-tool/lib/products/treasury/core";

import DollarInput from "../../../common/DollarInput";
import PercentInput from "../../../common/PercentInput";
import IncomeStatementTable from "../../../common/IncomeStatement";
import TitledPanel from "../../../common/TitledPanel";
import CurrencyAmount from "../../../common/CurrencyAmount";

import TreasuryServicesViewTable from "@/src/components/treasury/fields/TreasuryServicesViewTable";

import "./styles.scss";

type TreasuryViewerProps = {
  treasury: Treasury;
};

const TreasuryViewer = ({ treasury }: TreasuryViewerProps) => {
  return (
    <div className="treasury-viewer">
      {treasury && (
        <>
          <div className="two-column">
            <div className="col">
              <TitledPanel title="Inputs">
                <table className="inputs-table">
                  <tbody>
                    <tr>
                      <td>Investable Balance:</td>
                      <td>
                        <DollarInput
                          value={treasury.investableBalance}
                          onChange={(value) => {}}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>ECR:</td>
                      <td>
                        <PercentInput
                          value={treasury.earningsCreditRate}
                          onChange={(value) => {}}
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Discount:</td>
                      <td>
                        <PercentInput
                          value={treasury.discount}
                          onChange={(value) => {}}
                          disabled={true}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TitledPanel>
            </div>

            <div className="col">
              <TitledPanel title="Outputs">
                <IncomeStatementTable
                  feeOrInterest="fee"
                  incomeStatement={treasury.incomeStatement}
                >
                  <tr>
                    <td>Discounted Amount:</td>
                    <td>&nbsp;</td>
                    <td>
                      <CurrencyAmount amount={treasury.discountedAmount} />
                    </td>
                  </tr>
                </IncomeStatementTable>
              </TitledPanel>
            </div>
          </div>

          <div className="treasury-services-table-container">
            <TreasuryServicesViewTable
              // @ts-ignore
              treasuryServices={treasury.treasuryServices}
            />
          </div>
        </>
      )}

      {/*<pre className="text-gray-50">*/}
      {/*  {treasury && JSON.stringify(treasury, null, 2)}*/}
      {/*</pre>*/}
    </div>
  );
};

export default TreasuryViewer;
