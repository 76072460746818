import React, { forwardRef } from "react";
import { map } from "ramda";

import TreasuryServiceRow from "../TreasuryServiceRow";
import { TreasuryServiceRowRef } from "../TreasuryServiceRow/component";
import useShareForwardedRef from "../../../../../hooks/useSharedForwardedRef";
import { TreasuryService } from "@pricing-tool/lib/products/treasury/core";

type ProductFamilyRowGroupProps = {
  productFamily: string;
  treasuryServices: TreasuryService[];
};

const ProductFamilyRowGroup = forwardRef<
  TreasuryServiceRowRef,
  ProductFamilyRowGroupProps
>(
  (
    { productFamily, treasuryServices }: ProductFamilyRowGroupProps,
    forwardedRef,
  ) => {
    const firstRowRef =
      useShareForwardedRef<TreasuryServiceRowRef>(forwardedRef);

    const productFamilyRow = (
      <tr key={productFamily} className="product-family-row">
        <td colSpan={5} className="product-family">
          {productFamily}
        </td>
        <td className="edit">Discount</td>
        <td className="edit">Volume</td>
        <td className="edit">Price</td>
      </tr>
    );

    const rows = map(
      (treasuryService) => (
        <TreasuryServiceRow
          key={treasuryService.element.id}
          treasuryService={treasuryService}
        />
      ),
      treasuryServices,
    );
    return <tbody>{[productFamilyRow, ...rows]}</tbody>;
  },
);

export default ProductFamilyRowGroup;
