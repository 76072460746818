import React, { PropsWithChildren } from "react";

import "./styles.scss";
import { cn } from "@/lib/utils";

export type ModalProps = {
  title: string;
  width: number;
  contentHeight?: number;
  onClose: () => void;
};

const Modal = (props: PropsWithChildren<ModalProps>) => {
  const contentRef = React.useRef(null);

  // attach escape key
  React.useEffect(() => {
    const update = (e: KeyboardEvent) => {
      if (e.key === "Escape") props.onClose();
    };
    document.addEventListener("keydown", update);
    return () => document.removeEventListener("keydown", update);
  }, [props]);

  // This effect adds the modal-card-foot class to the content footer.form-actions
  // so that it effectively appears as part of the modal card "chrome"
  React.useLayoutEffect(() => {
    if (contentRef.current) {
      const ref: HTMLElement = contentRef.current;
      const body = ref.querySelector(".body");
      if (body) {
        // @ts-ignore
        body.style.height = `${props.contentHeight}px`;
      }

      const footer = ref.querySelector(".footer");
      if (footer) {
        footer.classList.add("modal-footer");
      }

      // Options for the observer (which mutations to observe)
      const config: MutationObserverInit = { subtree: true, childList: true };

      // Callback function to execute when mutations are observed
      const mutationCallback: MutationCallback = function (mutationsList) {
        const body = ref.querySelector(".body");
        if (body) {
          // @ts-ignore
          body.style.height = `${props.contentHeight}px`;
        }

        const footer = ref.querySelector(".footer");
        if (footer) {
          footer.classList.add("modal-footer");
        }
      };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(mutationCallback);

      // Start observing the target node for configured mutations
      observer.observe(ref, config);

      return () => {
        observer.disconnect();
      };
    }
  }, [contentRef, props]);

  return (
    <div
      className="component modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="centered">
        <div className="overlay" aria-hidden="true">
          &nbsp;
        </div>
        <span className="vert-centered" aria-hidden="true">
          &#8203;
        </span>
        <div className="rounded-box" style={{ width: props.width }}>
          <div className="title border-b">{props.title}</div>
          <div className="content" ref={contentRef}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
Modal.displayName = "Modal";

export const ModalFooter = ({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={cn("component footer p-4", className)}>{children}</div>
  );
};
ModalFooter.displayName = "ModalFooter";

export default Modal;
