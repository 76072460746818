import { Schema as S } from "@effect/schema";

import { OpportunityVersionFragment } from "@pricing-tool/graphql/lib/types";

import { OpportunityVersion, OpportunityVersionSchema } from "./core";

export type OpportunityVersionDto = OpportunityVersionFragment;

export const fromDto = (dto: OpportunityVersionDto): OpportunityVersion => {
  return S.decodeUnknownSync(OpportunityVersionSchema)(dto);
};
