import React, { forwardRef } from "react";

import DollarInput from "../../../../common/DollarInput";
import PercentInput from "../../../../common/PercentInput";
import InputWithIcon from "../../../../common/InputWithIcon";

import "./styles.scss";
import { TreasuryServiceRowProps } from "./props";

export interface TreasuryServiceRowRef {
  discount: HTMLInputElement | null;
  price: HTMLInputElement | null;
  volume: HTMLInputElement | null;
}

const TreasuryServiceRow = forwardRef<
  TreasuryServiceRowRef,
  TreasuryServiceRowProps
>(({ treasuryService }: TreasuryServiceRowProps, ref) => {
  const key = treasuryService.element.id;

  // @ts-ignore
  return (
    <tr className="treasury-service">
      <td className="field whitespace-nowrap w-20 pr-4">
        {treasuryService.element.id}
      </td>
      <td className="field whitespace-nowrap w-20 pr-4">
        {treasuryService.element.afpCode}
      </td>
      <td className="field lg:w-1/2 pr-4">{treasuryService.element.name}</td>
      <td className="field w-32 pr-4">
        <PercentInput
          value={treasuryService.discount}
          disabled={true}
          onChange={() => {}}
          className="w-32"
        />
      </td>
      <td className="field w-20 pr-4">
        <InputWithIcon
          value={treasuryService.monthlyVolume.toString()}
          onChange={(value) => {}}
          disabled={true}
          className="w-20"
        />
      </td>
      <td className="field w-36 pr-4">
        <DollarInput
          value={treasuryService.price}
          disabled={true}
          onChange={() => {}}
          decimalPlaces={6}
          className="w-36"
        />
      </td>
      <td className="field whitespace-nowrap px-2 max-w-30 text-right">
        ${treasuryService.revenue.toFormat(6)}
      </td>
      <td className="field whitespace-nowrap px-2 max-w-30 text-right">
        ${treasuryService.profit.toFormat(6)}
      </td>
    </tr>
  );
});

export default TreasuryServiceRow;
