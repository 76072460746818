import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  filter,
  find,
  findIndex,
  includes,
  isEmpty,
  isNil,
  map,
  pipe,
  prop,
  reduce,
  reject,
} from "ramda";
import {
  Outlet,
  useInRouterContext,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";

import {
  fromScenario,
  init,
  ScenarioDependencies,
  ScenarioModel,
} from "@pricing-tool/lib/scenario/model";
import { Product, ProductType } from "@pricing-tool/lib/products/core";
import { Element, Package } from "@pricing-tool/lib/products/treasury/core";
import { OpportunityModel } from "@pricing-tool/lib/opportunity/model";
import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { fromProduct } from "@pricing-tool/lib/products/model";
import { Scenario } from "@pricing-tool/lib/scenario/core";

import useOpportunityModel from "../../hooks/useOpportunityModel";

import Scenarios from "../opportunity/common/Scenarios";
import ScenarioEditor from "../ScenarioEditor";
import OpportunityActions from "../opportunity/common/OpportunityActions";
import OpportunityDetails from "../opportunity/common/OpportunityDetails";
import ScenarioComparatorModal from "./components/modals/ScenarioComparatorModal";
import ScenarioProductsPickerModal from "./components/modals/ScenarioProductsPickerModal";
import OpportunityScenarioPickerModal from "./components/modals/OpportunityScenarioPickerModal";
import OpportunityIncomeStatementModal from "./components/modals/OpportunityIncomeStatementModal";
import OpportunityScenarioProductsPickerModal from "./components/modals/OpportunityScenarioProductsPickerModal";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";
import { PerformanceTargets } from "../../utils/constructPerformance";

export type OpportunityEditorProps = {
  opportunityModel: OpportunityModel;
  dependencies: ScenarioDependencies;
  performanceTargets: PerformanceTargets;
  elementCatalog: Element[];
  packageCatalog: Package[];
  collateralRecoveryTable: CollateralRecoveryTable;
  onSaveOpportunity: (comment?: string) => void;
  onDeleteOpportunity: () => void;
};

const OpportunityEditor = ({
  opportunityModel,
  dependencies,
  performanceTargets,
  elementCatalog,
  packageCatalog,
  collateralRecoveryTable,
  onSaveOpportunity,
  onDeleteOpportunity,
}: OpportunityEditorProps) => {
  const { scenarioModels, opportunity } = useOpportunityModel(opportunityModel);

  const inRouterContext = useInRouterContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [scenarioIdToSelectedProduct, setScenarioIdToSelectedProduct] =
    useState<{ [key: string]: ProductType }>({});

  const [showOtherScenarioProductsPicker, setShowOtherScenarioProductsPicker] =
    useState(false);

  const [showOpportunityScenarioPicker, setShowOpportunityScenarioPicker] =
    useState(false);

  const [
    showOpportunityScenarioProductsPicker,
    setShowOpportunityScenarioProductsPicker,
  ] = useState(false);

  const [showOpportunityIncomeStatement, setShowOpportunityIncomeStatement] =
    useState(false);

  const selectProduct = useCallback(
    (scenarioId: string, productType: ProductType) => {
      const updatedScenarioIdToSelectedProduct = {
        ...scenarioIdToSelectedProduct,
        [scenarioId]: productType,
      };
      setScenarioIdToSelectedProduct(updatedScenarioIdToSelectedProduct);
    },
    [scenarioIdToSelectedProduct],
  );

  const [selectedScenarioModelId, setSelectedScenarioModelId] = useState<
    string | undefined
  >(params.scenarioId);

  const [scenarioComparisonModel, setScenarioComparisonModel] = useState<
    { selectedScenarioId: string; comparisonScenarioIds: string[] } | undefined
  >();

  useEffect(() => {
    if (!opportunity) return;

    if (!selectedScenarioModelId && opportunity.scenarios.length) {
      setSelectedScenarioModelId(opportunity.scenarios[0].id);
      return;
    }

    if (!selectedScenarioModelId) {
      inRouterContext &&
        navigate(`/opportunities/${opportunity.id}`, { replace: true });
      return;
    }

    if (
      selectedScenarioModelId &&
      !includes(selectedScenarioModelId, opportunity.scenarios.map(prop("id")))
    ) {
      console.log("resetting selected scenario ID");
      setSelectedScenarioModelId(undefined);
      return;
    }

    inRouterContext &&
      navigate(
        `/opportunities/${opportunity.id}/scenario/${selectedScenarioModelId}`,
        { replace: true },
      );
  }, [selectedScenarioModelId, opportunity, inRouterContext, navigate]);

  const addScenario = useCallback(
    (name: string) => {
      const scenarioModels$ = opportunityModel.input.scenarios;
      const scenarioModels = scenarioModels$.getValue();
      const getHighestSortOrder = pipe(
        map<ScenarioModel, number>((scenarioModel: ScenarioModel) =>
          scenarioModel.input.sortOrder.getValue(),
        ),
        reduce<number, number>((a, b) => (a > b ? a : b), 0),
      );
      const highestSortOrder = getHighestSortOrder(scenarioModels);

      const newScenario = init(name, dependencies, highestSortOrder + 1);
      const newScenarioId = newScenario.input.id;

      setSelectedScenarioModelId(newScenarioId);

      scenarioModels$.next([...scenarioModels, newScenario]);

      if (isEmpty(scenarioModels)) {
        opportunityModel.input.pipelineScenarioId.next(newScenarioId);
      }
    },
    [
      opportunityModel.input.scenarios,
      opportunityModel.input.pipelineScenarioId,
      dependencies,
    ],
  );

  const renameScenario = useCallback(
    (id, name) => {
      const scenarioModel = find(
        (scenarioModel) => scenarioModel.input.id === id,
        scenarioModels,
      );
      scenarioModel?.input.name.next(name);
    },
    [scenarioModels],
  );

  const removeScenario = useCallback(
    (id: string) => {
      const scenarioModels$ = opportunityModel.input.scenarios;
      const scenarioModels = scenarioModels$.getValue();
      const scenarioFilter =
        (filterId: string) => (scenarioModel: ScenarioModel) =>
          scenarioModel.input.id === filterId;
      const indexToRemove = findIndex(scenarioFilter(id), scenarioModels);
      const currentIndex = findIndex(
        scenarioFilter(selectedScenarioModelId!),
        scenarioModels,
      );
      const newScenarioModels = reject<ScenarioModel>(scenarioFilter(id))(
        scenarioModels,
      );

      let newIndex: number | undefined = currentIndex;
      if (indexToRemove <= currentIndex) {
        newIndex = currentIndex - 1;
      }
      if (newIndex < 0) {
        newIndex = newScenarioModels.length ? 0 : undefined;
      }
      const newSelectedScenarioModel = !isNil(newIndex)
        ? newScenarioModels[newIndex]
        : undefined;
      setSelectedScenarioModelId(newSelectedScenarioModel?.input.id);

      scenarioModels$.next(newScenarioModels);

      if (id === opportunity?.pipelineScenarioId) {
        opportunityModel.input.pipelineScenarioId.next(undefined);
      }
    },
    [
      opportunityModel.input.scenarios,
      opportunity?.pipelineScenarioId,
      opportunityModel.input.pipelineScenarioId,
      selectedScenarioModelId,
    ],
  );

  const duplicateScenario = useCallback(
    (id, name) => {
      if (!opportunity || !opportunity.scenarios) return;
      const scenario = find(
        (scenario) => scenario.id === id,
        opportunity.scenarios,
      );

      if (!scenario) return;

      const newScenario = fromScenario(
        { ...scenario, name, id: "" },
        dependencies,
      );
      const newScenarioId = newScenario.input.id;

      const scenarioModels$ = opportunityModel.input.scenarios;
      const scenarioModels = scenarioModels$.getValue();
      setSelectedScenarioModelId(newScenarioId);

      scenarioModels$.next([...scenarioModels, newScenario]);
    },
    [opportunityModel.input.scenarios, opportunity, dependencies],
  );

  const selectScenario = setSelectedScenarioModelId;

  const onEditOpportunityDetails = (opportunity: Opportunity) => {
    opportunityModel.input.name.next(opportunity.name);
    opportunityModel.input.clientId.next(opportunity.client!.id);
    opportunityModel.input.client.next(opportunity.client);
    opportunityModel.input.pricingDate.next(opportunity.pricingDate);
    opportunityModel.input.closeDate.next(opportunity.closeDate);
    opportunityModel.input.status.next(opportunity.status);
  };

  const onCloneProductFromThisOpportunityClicked = () =>
    setShowOtherScenarioProductsPicker(true);
  const onCloseOtherScenarioProductsPicker = () =>
    setShowOtherScenarioProductsPicker(false);

  const cloneProducts = useCallback(
    (_: string, products: Readonly<Product[]>) => {
      if (!selectedScenarioModelId) return;

      const currentScenarioModel = find(
        (scenarioModel) => scenarioModel.input.id === selectedScenarioModelId,
        opportunityModel.input.scenarios.getValue(),
      );

      if (!currentScenarioModel) {
        console.error("Failed to find current scenario!");
        return;
      }

      const existingProductTypes = map(
        (product) => product._type,
        currentScenarioModel.input.products.getValue(),
      );
      const newProducts = filter(
        (product) => !includes(product._type, existingProductTypes),
        products,
      );

      const newProductModels = map(
        (product) => fromProduct(product, dependencies),
        newProducts,
      );

      currentScenarioModel.input.products.next([
        ...currentScenarioModel.input.products.getValue(),
        ...newProductModels,
      ]);
      setShowOtherScenarioProductsPicker(false);
      setShowOpportunityScenarioProductsPicker(false);

      const firstNewProduct = newProductModels[0]?._type;
      firstNewProduct &&
        selectProduct(selectedScenarioModelId, firstNewProduct);
    },
    [
      selectedScenarioModelId,
      opportunityModel.input.scenarios,
      dependencies,
      selectProduct,
    ],
  );

  const onCloneScenarioClicked = () => setShowOpportunityScenarioPicker(true);
  const onCloseOpportunityScenarioPicker = () =>
    setShowOpportunityScenarioPicker(false);

  const cloneScenario = useCallback(
    (scenario: Scenario) => {
      const scenarioModel = fromScenario({ ...scenario, id: "" }, dependencies);
      const oldScenarioModels = opportunityModel.input.scenarios.getValue();
      opportunityModel.input.scenarios.next([
        ...oldScenarioModels,
        scenarioModel,
      ]);

      setSelectedScenarioModelId(scenarioModel.input.id);
      setShowOpportunityScenarioPicker(false);

      if (isEmpty(oldScenarioModels)) {
        opportunityModel.input.pipelineScenarioId.next(scenarioModel.input.id);
      }
    },
    [
      opportunityModel.input.scenarios,
      opportunityModel.input.pipelineScenarioId,
      dependencies,
    ],
  );

  const onCloneProductFromOtherOpportunityClicked = () =>
    setShowOpportunityScenarioProductsPicker(true);
  const onCloseOpportunityScenarioProductsPicker = () =>
    setShowOpportunityScenarioProductsPicker(false);

  const onMakePipelineScenario = useCallback(
    (scenarioId: string) => {
      opportunityModel.input.pipelineScenarioId.next(scenarioId);
    },
    [opportunityModel],
  );

  if (!opportunity) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>Opportunity Editor - {opportunity.name}</title>
      </Helmet>
      <div className="p-4">
        <OpportunityActions
          saveButtonDisabled={!opportunity.isDirty}
          onSaveOpportunity={onSaveOpportunity}
          onDeleteOpportunity={onDeleteOpportunity}
          onShowIncomeStatement={() => setShowOpportunityIncomeStatement(true)}
          onShowDocuments={() => {
            navigate(`/opportunities/${params.opportunityId}/documents`, {
              state: {
                backgroundLocation: location,
              },
            });
          }}
          onShowVersionHistoryModal={() => {
            navigate(`/opportunities/${params.opportunityId}/history`, {
              state: {
                backgroundLocation: location,
              },
            });
          }}
        />
      </div>
      <OpportunityDetails
        opportunity={opportunity}
        onEdit={onEditOpportunityDetails}
      />
      <Scenarios
        opportunityId={opportunity.id}
        scenarios={opportunity?.scenarios || []}
        performanceTargets={performanceTargets}
        pipelineScenarioId={opportunity?.pipelineScenarioId}
        selectedScenarioId={selectedScenarioModelId}
        onAddScenario={addScenario}
        onCloneScenarioClicked={onCloneScenarioClicked}
        onSelectScenario={selectScenario}
        onRemoveScenario={removeScenario}
        onRenameScenario={renameScenario}
        onDuplicateScenario={duplicateScenario}
        onCompareScenario={(selectedScenarioId, comparisonScenarioIds) =>
          setScenarioComparisonModel({
            selectedScenarioId,
            comparisonScenarioIds,
          })
        }
        onMakePipelineScenario={onMakePipelineScenario}
        onCreateDocument={() => null} // enables the create document button
      />
      {inRouterContext && selectedScenarioModelId && (
        <Outlet
          key={selectedScenarioModelId}
          context={{
            scenarioModel: find(
              (model) => model.input.id === selectedScenarioModelId,
              scenarioModels,
            ),
            onSelectProduct: selectProduct,
            onCloneProductFromThisOpportunityClicked:
              onCloneProductFromThisOpportunityClicked,
            onCloneProductFromOtherOpportunityClicked:
              onCloneProductFromOtherOpportunityClicked,
            selectedProduct:
              scenarioIdToSelectedProduct[selectedScenarioModelId],
            dependencies,
            elementCatalog,
            packageCatalog,
            collateralRecoveryTable,
          }}
        />
      )}
      {!inRouterContext &&
        map(
          (scenarioModel) => (
            <div
              key={scenarioModel.input.id}
              className={
                scenarioModel.input.id === selectedScenarioModelId
                  ? ""
                  : "hidden"
              }
            >
              <ScenarioEditor
                scenarioModel={scenarioModel}
                onSelectProduct={selectProduct}
                onCloneProductFromThisOpportunityClicked={
                  onCloneProductFromThisOpportunityClicked
                }
                onCloneProductFromOtherOpportunityClicked={
                  onCloneProductFromOtherOpportunityClicked
                }
                selectedProduct={
                  scenarioIdToSelectedProduct[scenarioModel.input.id]
                }
                dependencies={dependencies}
                elementCatalog={elementCatalog}
                packageCatalog={packageCatalog}
                collateralRecoveryTable={collateralRecoveryTable}
              />
            </div>
          ),
          scenarioModels,
        )}

      {scenarioComparisonModel && (
        <ScenarioComparatorModal
          scenarios={opportunity.scenarios}
          performanceTargets={performanceTargets}
          selectedScenarioId={scenarioComparisonModel.selectedScenarioId}
          comparisonScenarioIds={scenarioComparisonModel.comparisonScenarioIds}
          onClose={() => setScenarioComparisonModel(undefined)}
        />
      )}

      {showOpportunityIncomeStatement && (
        <OpportunityIncomeStatementModal
          opportunity={opportunity}
          performanceTargets={performanceTargets}
          onClose={() => setShowOpportunityIncomeStatement(false)}
        />
      )}

      {showOtherScenarioProductsPicker && (
        <ScenarioProductsPickerModal
          opportunity={opportunity}
          onCancel={onCloseOtherScenarioProductsPicker}
          onConfirm={cloneProducts}
          excludedScenarioId={selectedScenarioModelId}
        />
      )}

      {showOpportunityScenarioPicker && (
        <OpportunityScenarioPickerModal
          onCancel={onCloseOpportunityScenarioPicker}
          onConfirm={cloneScenario}
        />
      )}

      {showOpportunityScenarioProductsPicker && (
        <OpportunityScenarioProductsPickerModal
          onCancel={onCloseOpportunityScenarioProductsPicker}
          onConfirm={cloneProducts}
        />
      )}
    </>
  );
};

export default OpportunityEditor;
