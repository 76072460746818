import React, { useCallback, useEffect, useState } from "react";
import { find, includes, map, prop } from "ramda";
import {
  Outlet,
  useInRouterContext,
  useNavigate,
  useParams,
} from "react-router";
import { ProductType } from "@pricing-tool/lib/products/core";
import { Opportunity } from "@pricing-tool/lib/opportunity/core";

import Scenarios from "../opportunity/common/Scenarios";
import OpportunityActions from "../opportunity/common/OpportunityActions";
import OpportunityDetails from "../opportunity/common/OpportunityDetails";
import ScenarioComparatorModal from "../OpportunityEditor/components/modals/ScenarioComparatorModal";
import OpportunityIncomeStatementModal from "../OpportunityEditor/components/modals/OpportunityIncomeStatementModal";
import { PerformanceTargets } from "../../utils/constructPerformance";
import ScenarioViewer from "@/src/components/ScenarioViewer";

export type OpportunityViewerProps = {
  opportunity: Opportunity;
  performanceTargets: PerformanceTargets;
};

const OpportunityViewer = ({
  opportunity,
  performanceTargets,
}: OpportunityViewerProps) => {
  const inRouterContext = useInRouterContext();
  const navigate = useNavigate();
  const params = useParams();
  const { opportunityId, opportunityVersionId } = params;

  const [scenarioIdToSelectedProduct, setScenarioIdToSelectedProduct] =
    useState<{ [key: string]: ProductType }>({});

  const [showOpportunityIncomeStatement, setShowOpportunityIncomeStatement] =
    useState(false);

  const selectProduct = useCallback(
    (scenarioId: string, productType: ProductType) => {
      const updatedScenarioIdToSelectedProduct = {
        ...scenarioIdToSelectedProduct,
        [scenarioId]: productType,
      };
      setScenarioIdToSelectedProduct(updatedScenarioIdToSelectedProduct);
    },
    [scenarioIdToSelectedProduct],
  );

  const [selectedScenarioId, setSelectedScenarioId] = useState<
    string | undefined
  >(params.scenarioId);

  const [scenarioComparisonModel, setScenarioComparisonModel] = useState<
    { selectedScenarioId: string; comparisonScenarioIds: string[] } | undefined
  >();

  useEffect(() => {
    if (!opportunity) return;

    if (!selectedScenarioId && opportunity.scenarios.length) {
      setSelectedScenarioId(opportunity.scenarios[0].id);
      return;
    }

    if (!selectedScenarioId) {
      inRouterContext &&
        navigate(
          `/opportunities/${opportunityId}/history/${opportunityVersionId}`,
          { replace: true },
        );
      return;
    }

    if (
      selectedScenarioId &&
      !includes(selectedScenarioId, opportunity.scenarios.map(prop("id")))
    ) {
      console.log("resetting selected scenario ID");
      setSelectedScenarioId(undefined);
      return;
    }

    inRouterContext &&
      navigate(
        `/opportunities/${opportunityId}/history/${opportunityVersionId}/scenario/${selectedScenarioId}`,
        { replace: true },
      );
  }, [selectedScenarioId, opportunity, inRouterContext, navigate]);

  const selectScenario = setSelectedScenarioId;

  if (!opportunity) {
    return <></>;
  }

  return (
    <>
      <div className="p-4">
        <OpportunityActions
          saveButtonDisabled={true}
          onShowIncomeStatement={() => setShowOpportunityIncomeStatement(true)}
        />
      </div>
      <OpportunityDetails opportunity={opportunity} />
      <Scenarios
        opportunityId={opportunity.id}
        scenarios={opportunity?.scenarios || []}
        performanceTargets={performanceTargets}
        pipelineScenarioId={opportunity?.pipelineScenarioId}
        selectedScenarioId={selectedScenarioId}
        onSelectScenario={selectScenario}
        onCompareScenario={(selectedScenarioId, comparisonScenarioIds) =>
          setScenarioComparisonModel({
            selectedScenarioId,
            comparisonScenarioIds,
          })
        }
      />
      {inRouterContext && selectedScenarioId && (
        <Outlet
          key={selectedScenarioId}
          context={{
            scenario: find(
              (scenario) => scenario.id === selectedScenarioId,
              opportunity.scenarios,
            ),
            onSelectProduct: selectProduct,
            selectedProduct: scenarioIdToSelectedProduct[selectedScenarioId],
          }}
        />
      )}
      {!inRouterContext &&
        map(
          (scenario) => (
            <div
              key={scenario.id}
              className={scenario.id === selectedScenarioId ? "" : "hidden"}
            >
              <ScenarioViewer
                scenario={scenario}
                onSelectProduct={selectProduct}
                selectedProduct={scenarioIdToSelectedProduct[scenario.id]}
              />
            </div>
          ),
          opportunity.scenarios,
        )}

      {scenarioComparisonModel && (
        <ScenarioComparatorModal
          scenarios={opportunity.scenarios}
          performanceTargets={performanceTargets}
          selectedScenarioId={scenarioComparisonModel.selectedScenarioId}
          comparisonScenarioIds={scenarioComparisonModel.comparisonScenarioIds}
          onClose={() => setScenarioComparisonModel(undefined)}
        />
      )}

      {showOpportunityIncomeStatement && (
        <OpportunityIncomeStatementModal
          opportunity={opportunity}
          performanceTargets={performanceTargets}
          onClose={() => setShowOpportunityIncomeStatement(false)}
        />
      )}
    </>
  );
};

export default OpportunityViewer;
