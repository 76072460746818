import React from "react";
import { ChevronRight, Pencil } from "lucide-react";
import { match } from "ts-pattern";
import moment from "moment";

import { Opportunity as OpportunityType } from "@pricing-tool/lib/opportunity/core";

import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";

export type OpportunityDetailsProps = {
  opportunity: OpportunityType;
  collapsed?: boolean;
  onCollapseToggle: () => void;
  onEditButtonPressed?: () => void;
};

function OpportunityDetailRow({ label, value }) {
  return (
    <div className="flex items-center justify-between py-1">
      <span className="text-sm text-muted-foreground">{label}</span>
      <span className="text-sm font-medium">{value || "--"}</span>
    </div>
  );
}

const OpportunityDetails = ({
  collapsed,
  opportunity,
  onCollapseToggle,
  onEditButtonPressed,
}: OpportunityDetailsProps) => {
  const status = match(opportunity.status)
    .with("IN_PROGRESS", () => "In Progress")
    .with("LOST", () => "Lost")
    .with("WON", () => "Won")
    .otherwise(() => "Unknown");

  if (collapsed) {
    return (
      <div className="flex items-center py-2 px-4 border-t-gray-100 border-t-2">
        <span className="flex w-4 items-center">
          <button onClick={onCollapseToggle}>
            <ChevronRight size="sm" />
          </button>
        </span>
        <span className="px-4 text-lg">
          {opportunity.client?.name || "Unknown Client"} -{" "}
          <span className="font-bold">{opportunity.name}</span>
        </span>
        <Badge variant="outline">{status}</Badge>
        {onEditButtonPressed && (
          <span className="flex w-4 items-center">
            <Button size="sm" variant="link" onClick={onEditButtonPressed}>
              <Pencil size="sm" />
            </Button>
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="py-2 px-4 border-t-gray-100 border-t-2">
      <div className="flex items-center">
        <span className="flex w-4 items-center">
          <button onClick={onCollapseToggle}>
            <ChevronRight size="sm" />
          </button>
        </span>
        <span className="px-4 text-lg">
          {opportunity.client?.name || "Unknown Client"} -{" "}
          <span className="font-bold">{opportunity.name}</span>
        </span>
        <Badge variant="outline">{status}</Badge>
        {onEditButtonPressed && (
          <span className="flex w-4 items-center">
            <Button size="sm" variant="link" onClick={onEditButtonPressed}>
              <Pencil size="sm" />
            </Button>
          </span>
        )}
      </div>
      <div className="grid gap-4 p-4 pl-8 sm:grid-cols-2">
        <div className="space-y-2">
          <OpportunityDetailRow
            label="Client"
            value={opportunity.client?.name}
          />
          <OpportunityDetailRow label="Owner" value={null} />
        </div>
        <div className="space-y-2">
          <OpportunityDetailRow
            label="Pricing Date"
            value={
              opportunity.pricingDate
                ? moment(opportunity.pricingDate).format("LL")
                : "--"
            }
          />
          <OpportunityDetailRow
            label="Close Date"
            value={
              opportunity.closeDate
                ? moment(opportunity.closeDate).format("LL")
                : "--"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
