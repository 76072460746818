import { Schema as S } from "@effect/schema";

import { ClientSchema } from "../client/core";
import { IncomeStatementSchema } from "../incomeStatement";
import { OpportunityStatusSchema } from "../opportunity/core";
import NullToUndefined from "../util/NullToUndefined";

export const OpportunitySummarySchema = S.Struct({
  id: S.String,
  branchId: S.String,
  clientId: S.String,
  name: S.String,
  owner: S.optional(NullToUndefined(S.String)),
  status: OpportunityStatusSchema,
  pricingDate: S.optional(NullToUndefined(S.String)),
  closeDate: S.optional(NullToUndefined(S.String)),
  region: S.optional(NullToUndefined(S.String)),
  client: S.optional(S.mutable(ClientSchema)),
  incomeStatement: S.optional(S.NullishOr(IncomeStatementSchema)),
});

export type OpportunitySummary = S.Schema.Type<typeof OpportunitySummarySchema>;
