import React from "react";

import { Deposits } from "@pricing-tool/lib/products/deposits/core";

import DollarInput from "../../../common/DollarInput";
import IncomeStatementTable from "../../../common/IncomeStatement";
import TitledPanel from "../../../common/TitledPanel";
import PercentInput from "../../../common/PercentInput";
import CurrencyAmount from "../../../common/CurrencyAmount";
import PercentAmount from "../../../common/PercentAmount";

import "./styles.scss";

export type DepositsViewerProps = {
  deposits: Deposits;
};

const DepositsViewer = ({ deposits }: DepositsViewerProps) => {
  if (!deposits) {
    return <></>;
  }

  return (
    <div className="deposits-viewer">
      <div className="two-column">
        <div className="col">
          <TitledPanel title="Inputs">
            <table className="inputs-table">
              <tbody>
                <tr>
                  <td>Collected Balances:</td>
                  <td>
                    <DollarInput
                      value={deposits.collectedBalances}
                      onChange={(value) => {}}
                      disabled={true}
                      decimalPlaces={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td>ECR-Eligible Balances:</td>
                  <td>
                    <DollarInput
                      value={deposits.ecrEligibleBalances}
                      onChange={(value) => {}}
                      disabled={true}
                      decimalPlaces={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Interest-Eligible Balances:</td>
                  <td>
                    <DollarInput
                      value={deposits.interestEligibleBalances}
                      onChange={(value) => {}}
                      disabled={true}
                      decimalPlaces={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>FTP Rate:</td>
                  <td>
                    <PercentInput
                      value={deposits.fundTransferPricingRate}
                      onChange={(value) => {}}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>LTP Rate:</td>
                  <td>
                    <PercentInput
                      value={deposits.liquidityTransferPricingRate}
                      onChange={(value) => {}}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Annualized ECR:</td>
                  <td>
                    <PercentInput
                      value={deposits.annualizedEcr}
                      onChange={(value) => {}}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Annualized Interest Rate:</td>
                  <td>
                    <PercentInput
                      value={deposits.annualizedInterestRate}
                      onChange={(value) => {}}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Annual ECR-Eligible Charges:</td>
                  <td>
                    <DollarInput
                      value={deposits.annualEcrEligibleCharges}
                      onChange={(value) => {}}
                      disabled={true}
                      decimalPlaces={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Reserve Requirements:</td>
                  <td>
                    <PercentInput
                      value={deposits.reserveRequirements}
                      onChange={(value) => {}}
                      disabled={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TitledPanel>
        </div>

        <div className="col">
          <TitledPanel title="Outputs">
            <IncomeStatementTable
              incomeStatement={deposits.incomeStatement}
              feeOrInterest="interest"
            >
              <tr>
                <td>Earnings Credit Allowance (ECA):</td>
                <td>&nbsp;</td>
                <td>
                  <CurrencyAmount amount={deposits.earningsCreditAllowance} />
                </td>
              </tr>
              <tr>
                <td>Net Interest Margin (NIM):</td>
                <td>&nbsp;</td>
                <td>
                  <PercentAmount amount={deposits.netInterestMargin} />
                </td>
              </tr>
              <tr>
                <td>Net Income:</td>
                <td>&nbsp;</td>
                <td>
                  <CurrencyAmount amount={deposits.netIncome} />
                </td>
              </tr>
            </IncomeStatementTable>
          </TitledPanel>
        </div>
      </div>

      {/*<pre className="text-gray-300">*/}
      {/*  {deposits && JSON.stringify(deposits, null, 2)}*/}
      {/*</pre>*/}
    </div>
  );
};

export default DepositsViewer;
