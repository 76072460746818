import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductProps } from "./props";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";

const Component = ({
  product,
  selected,
  selectProduct,
  removeProduct,
}: ProductProps) => {
  const { name, keyMetricName, keyMetricUnit, keyMetric } = product;
  return (
    <div
      className={cn(
        "flex flex-none w-min",
        selected && "border-l-4 border-blue-600",
        !selected && "bg-gray-50 border-l-4 border-transparent",
      )}
    >
      <div className="flex px-2 flex-col text-sm justify-evenly w-32 py-3">
        <Button
          variant="link"
          size="inline"
          className="justify-start mb-0.5"
          onClick={selectProduct}
        >
          {product.name}
        </Button>
        <Badge variant="outline" className="w-fit">
          {keyMetricName}:{" "}
          {keyMetric && (
            <b>
              {keyMetricUnit === "dollar"
                ? `$${keyMetric.toFormat(0)}`
                : `${keyMetric.mul(100).toFormat(2)}%`}
            </b>
          )}
        </Badge>
      </div>
      {removeProduct && (
        <button
          onClick={(e) => {
            e.preventDefault();
            removeProduct();
          }}
          className="flex flex-none w-8 aspect-square justify-center items-center rounded-md text-gray-300 hover:text-red-300"
        >
          <FontAwesomeIcon icon="minus-square" />
        </button>
      )}
    </div>
  );
};

export default Component;
