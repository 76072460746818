import React, { useMemo } from "react";
import {
  ascend,
  groupBy,
  map,
  mapObjIndexed,
  sortBy,
  sortWith,
  toPairs,
} from "ramda";

import { TreasuryService } from "@pricing-tool/lib/products/treasury/core";

import ProductFamilyRowGroup from "./ProductFamilyRowGroup";

import "./styles.scss";

type TreasuryServicesViewTableProps = {
  readonly treasuryServices: TreasuryService[] | undefined;
};

const groupingFunction = (model: TreasuryService) =>
  model.element.productFamily;

const sortCriteria = [
  ascend((model: TreasuryService) => model.element.productFamily),
  ascend((model: TreasuryService) => model.element.afpCode),
  ascend((model: TreasuryService) => model.element.id),
];

const TreasuryServicesViewTable = ({
  treasuryServices,
}: TreasuryServicesViewTableProps) => {
  const groupedTreasuryServices: {
    [key in string]: TreasuryService[];
  } = useMemo(() => {
    const grouped = treasuryServices
      ? groupBy(groupingFunction, treasuryServices)
      : {};
    return mapObjIndexed(sortWith<TreasuryService>(sortCriteria), grouped);
  }, [treasuryServices]);

  const groupedTreasuryServicePairs = toPairs(groupedTreasuryServices);
  const sortedGroupedTreasuryServicePairs = sortBy(
    ([productFamily, models]) => productFamily,
    groupedTreasuryServicePairs,
  );

  return (
    <table className="treasury-services-view-table">
      <thead>
        <tr>
          <th>Element</th>
          <th>AFP Code</th>
          <th>Service Description</th>
          <th>Discount</th>
          <th>Volume</th>
          <th>Price</th>
          <th className="right">Revenue</th>
          <th className="right">Profit</th>
        </tr>
      </thead>
      {map(
        ([productFamily, models]) => (
          <ProductFamilyRowGroup
            key={productFamily}
            productFamily={productFamily}
            treasuryServices={models}
          />
        ),
        sortedGroupedTreasuryServicePairs,
      )}
    </table>
  );
};

export default TreasuryServicesViewTable;
