import React, { forwardRef } from "react";

import TreasuryServiceRowComponent, {
  TreasuryServiceRowRef,
} from "./component";
import { TreasuryService } from "@pricing-tool/lib/products/treasury/core";

type TreasuryServiceRowProps = {
  treasuryService: TreasuryService;
};

const TreasuryServiceRow = forwardRef<
  TreasuryServiceRowRef,
  TreasuryServiceRowProps
>(({ treasuryService }: TreasuryServiceRowProps, ref) => {
  if (!treasuryService) return <></>;

  return (
    <TreasuryServiceRowComponent
      ref={ref}
      key={treasuryService.element.id}
      treasuryService={treasuryService}
    />
  );
});

export default TreasuryServiceRow;
