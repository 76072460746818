import { Schema as S } from "@effect/schema";

import { OpportunityVersionSummaryFragment } from "@pricing-tool/graphql/lib/types";

import {
  OpportunityVersionSummary,
  OpportunityVersionSummarySchema,
} from "./core";

export type OpportunityVersionSummaryDto = OpportunityVersionSummaryFragment;

export const fromDto = (
  dto: OpportunityVersionSummaryDto,
): OpportunityVersionSummary => {
  return S.decodeUnknownSync(OpportunityVersionSummarySchema)(dto);
};

export const toDto = (
  opportunity: OpportunityVersionSummary,
): OpportunityVersionSummaryDto => {
  return S.encodeSync(OpportunityVersionSummarySchema)(opportunity);
};
