import React from "react";
import moment from "moment";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useLocation, useParams } from "react-router";
import { match } from "ts-pattern";
import { ExternalLink } from "lucide-react";

import { OpportunityVersionSummary } from "@pricing-tool/lib/opportunityVersionSummary/core";

import { DataTable } from "@/components/ui/data-table";

import {
  constructPerformance,
  PerformanceTargets,
} from "@/src/utils/constructPerformance";
import PerformanceMetric from "@/src/components/opportunity/fields/PerformanceMetric";

import "./styles.scss";

export type HistoryTableProps = {
  versions: OpportunityVersionSummary[];
  performanceTargets: PerformanceTargets;
};

const HistoryTable = ({ versions, performanceTargets }: HistoryTableProps) => {
  const { opportunityId } = useParams();
  const location = useLocation();

  let state = location.state as { backgroundLocation?: Location } | null;

  if (!versions || versions.length === 0) {
    return <div className="p-4">No history</div>;
  }

  const columnHelper = createColumnHelper<OpportunityVersionSummary>();

  const columns: ColumnDef<OpportunityVersionSummary, any>[] = [
    columnHelper.accessor("id", {
      cell: (version) => (
        <a
          href={`/opportunities/${opportunityId}/history/${version.getValue()}`}
          target="_blank"
        >
          <ExternalLink className="w-4 h-4" />
        </a>
      ),
    }),
    columnHelper.accessor("updatedAt", {
      cell: (version) =>
        moment(version.getValue()).format("D MMM YYYY, hh:mm A"),
      header: "Date/Time",
    }),
    columnHelper.accessor("updatedBy", {
      cell: (version) => version.getValue(),
      header: "By",
    }),
    columnHelper.accessor("opportunity.status", {
      cell: (version) =>
        match(version.getValue())
          .with("IN_PROGRESS", () => "In Progress")
          .with("LOST", () => "Lost")
          .with("WON", () => "Won")
          .otherwise(() => "Unknown"),
      header: "Status",
    }),
    columnHelper.accessor("annotation", {
      cell: (version) => version.getValue(),
      header: "Description",
    }),
    columnHelper.accessor("opportunity.incomeStatement.returnOnEquity", {
      cell: (version) => {
        const performance = constructPerformance(
          version.row.original.opportunity?.incomeStatement?.returnOnEquity,
          version.row.original.opportunity?.incomeStatement?.returnOnAssets,
          performanceTargets,
        );
        return (
          <PerformanceMetric
            metric={version.getValue()}
            performance={performance.returnOnEquityPerformance}
          />
        );
      },
      header: "ROE",
    }),
    columnHelper.accessor("opportunity.incomeStatement.returnOnAssets", {
      cell: (version) => {
        const performance = constructPerformance(
          version.row.original.opportunity?.incomeStatement?.returnOnEquity,
          version.row.original.opportunity?.incomeStatement?.returnOnAssets,
          performanceTargets,
        );
        return (
          <PerformanceMetric
            metric={version.getValue()}
            performance={performance.returnOnAssetsPerformance}
          />
        );
      },
      header: "ROA",
    }),
  ];

  return (
    <div className="component history-table">
      <DataTable columns={columns} data={versions} />
    </div>
  );
};

export default HistoryTable;
