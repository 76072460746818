import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router";
import { Plus } from "lucide-react";
import { toLower } from "ramda";

import {
  ClientFragment,
  useGetClientsLazyQuery,
  useSearchClientsByNameLazyQuery,
} from "@pricing-tool/graphql/lib/react";

import ClientsTable from "../../components/client/tables/ClientsTable";
import useDebounce from "../../hooks/useDebounce";
import { Button } from "@/components/ui/button";

function ClientsPage() {
  const navigate = useNavigate();

  const [displayResults, setDisplayResults] = useState<
    ClientFragment[] | undefined
  >(undefined);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchClients, { loading: searching, error: searchError }] =
    useSearchClientsByNameLazyQuery();

  const onSearch = useCallback(
    (searchTerm: string) => {
      if (!searchTerm || searchTerm === "") {
        void getClients();
        return;
      }

      void searchClients({
        variables: {
          branchId: "branch-1",
          searchTerm: toLower(searchTerm),
        },
        onCompleted: (data) => {
          setDisplayResults(data.searchClientsByName.items);
        },
      });
    },
    [searchClients],
  );

  const { onChange: debouncedInputChange } = useDebounce(300, onSearch);

  const [getClients, { loading, error }] = useGetClientsLazyQuery({
    variables: {
      branchId: "branch-1",
      paginationOptions: {
        limit: 100,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setDisplayResults(data.getClients.items);
    },
  });

  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    void getClients();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className="page clients">
      <Helmet>
        <title>Clients</title>
      </Helmet>
      <div className="text-2xl mb-4">Clients</div>

      <Button variant="outline" onClick={() => navigate("/clients/new")}>
        <Plus />
        Create a new client
      </Button>

      <div className="flex flex-row items-center py-3 gap-3">
        <span>Search for clients by name: </span>
        <input
          ref={inputRef}
          className="focus:ring-2 focus:ring-blue-500 ring-2 ring-blue-400 border border-gray-300 rounded-md px-2"
          value={searchTerm}
          onChange={(event) => {
            console.log("onChange", event.target.value);
            setSearchTerm(event.target.value);
            debouncedInputChange(event);
          }}
        />
        {loading && <div>Loading...</div>}
        {searching && <div>Loading...</div>}
      </div>

      {error && <div>Error: {error.message}</div>}
      {searchError && <div>Error: {searchError.message}</div>}

      {displayResults && (
        <ClientsTable
          clients={displayResults}
          onSelect={(template) => navigate(`/clients/${template.id}`)}
        />
      )}
    </div>
  );
}

export default ClientsPage;
