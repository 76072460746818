import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Copy,
  EllipsisVertical,
  GitCompare,
  Pencil,
  ScrollText,
  Star,
  Trash,
} from "lucide-react";
import { none } from "ramda";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

import { ScenarioSummary } from "../../../OpportunityEditor/utils/ScenarioSummary";

import "./styles.scss";

export type ScenarioProps = {
  scenario: ScenarioSummary;
  onSelect: () => void;
  onRemove?: () => void;
  onRename?: () => void;
  onDuplicate?: () => void;
  onCompareWith?: () => void;
  onMakePipelineScenario?: () => void;
  onCreateDocument?: () => void;
};

const performanceColor: { [key: string]: string } = {
  meets: "bg-green-400",
  close: "bg-amber-400",
  neutral: "bg-gray-300",
  poor: "bg-red-500",
};

const performanceIcon: { [key: string]: IconProp | undefined } = {
  meets: ["fas", "battery-full"],
  close: ["fas", "battery-three-quarters"],
  neutral: undefined,
  poor: ["fas", "battery-quarter"],
};

const ScenarioMenu = (props: ScenarioProps) => {
  const {
    onRemove,
    onRename,
    onDuplicate,
    onCompareWith,
    onMakePipelineScenario,
    onCreateDocument,
  } = props;

  if (
    none(Boolean, [
      onRemove,
      onRename,
      onDuplicate,
      onCompareWith,
      onMakePipelineScenario,
      onCreateDocument,
    ])
  ) {
    return <div className="w-6 h-6" />;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="w-full text-gray-300 hover:text-blue-300"
        asChild
      >
        <EllipsisVertical className="w-6 h-6" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-44 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
        forceMount
      >
        {onMakePipelineScenario ? (
          <DropdownMenuItem
            onSelect={onMakePipelineScenario}
            className="cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-sm"
          >
            <Star />
            Make Pipeline
          </DropdownMenuItem>
        ) : (
          <></>
        )}
        {onCreateDocument ? (
          <DropdownMenuItem
            onSelect={onCreateDocument}
            className="cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-sm"
          >
            <ScrollText />
            Create Document
          </DropdownMenuItem>
        ) : (
          <></>
        )}
        {onRename && (
          <DropdownMenuItem
            onSelect={onRename}
            className="cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-sm"
          >
            <Pencil />
            Rename
          </DropdownMenuItem>
        )}
        {onDuplicate && (
          <DropdownMenuItem
            onSelect={onDuplicate}
            className="cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-sm"
          >
            <Copy />
            Duplicate
          </DropdownMenuItem>
        )}
        {onCompareWith ? (
          <DropdownMenuItem
            onSelect={onCompareWith}
            className="cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-sm"
          >
            <GitCompare />
            Compare with...
          </DropdownMenuItem>
        ) : (
          <></>
        )}
        {onRemove && (
          <DropdownMenuItem
            onSelect={onRemove}
            className="cursor-pointer group flex rounded-md items-center w-full px-2 py-2 text-sm text-red-400"
          >
            <Trash />
            Delete
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const Component = (props: ScenarioProps) => {
  const { scenario, onSelect, onMakePipelineScenario } = props;
  const { name, selected, metrics, performance } = scenario;
  const { returnOnEquity, returnOnAssets } = metrics;
  const { aggregatePerformance } = performance;

  return (
    <div className="relative">
      {!onMakePipelineScenario && (
        <>
          <div className="pipeline-star pointer-events-none">
            <FontAwesomeIcon icon="star" size="lg" className="bigger" />
            <FontAwesomeIcon icon="star" size="lg" />
          </div>
        </>
      )}
      <Card
        className={cn("flex flex-row", selected && "border-gray-700 border-2")}
      >
        <div
          className={`${performanceColor[aggregatePerformance]} flex flex-none w-12 aspect-square justify-center items-center rounded-l-xl`}
        >
          {aggregatePerformance && performanceIcon[aggregatePerformance] && (
            <FontAwesomeIcon
              icon={performanceIcon[aggregatePerformance]!}
              size="lg"
              color="white"
            />
          )}
        </div>
        <div className="flex p-2 flex-col text-sm justify-evenly w-32">
          <Button
            variant="link"
            size="inline"
            onClick={onSelect}
            title={name}
            className="justify-start mb-2"
          >
            <div className="truncate text-left font-semibold">{name}</div>
          </Button>
          <div className="whitespace-nowrap text-xs">
            <span className="font-thin">ROE</span>:{" "}
            {returnOnEquity ? (
              <span className="font-normal">
                {returnOnEquity.mul(100).toFormat(2)}%
              </span>
            ) : (
              <span className="font-normal">Unknown</span>
            )}
          </div>
          <div className="whitespace-nowrap text-xs">
            <span className="font-thin">ROA</span>:{" "}
            {returnOnAssets ? (
              <span className="font-normal">
                {returnOnAssets.mul(100).toFormat(2)}%
              </span>
            ) : (
              <span className="font-normal">Unknown</span>
            )}
          </div>
        </div>
        <div
          className={`flex flex-none w-8 aspect-square justify-center items-center rounded-md`}
        >
          <ScenarioMenu {...props} />
        </div>
      </Card>
    </div>
  );
};

export default Component;
