import "./styles.scss";
import Decimal from "decimal.js-light";
import InputWithIcon from "../InputWithIcon";
import { isNil } from "ramda";
import { forwardRef } from "react";
import { DollarInputProps } from "./props";

const DollarInput = forwardRef<HTMLInputElement, DollarInputProps>(
  (props: DollarInputProps, ref) => {
    const onChange = (newValue: string) =>
      props.onChange(new Decimal(newValue.replaceAll(",", "")));
    return (
      <div className="component dollar-input">
        <InputWithIcon
          ref={ref}
          value={
            props.value
              ? props.value.toFormat(
                  isNil(props.decimalPlaces) ? 2 : props.decimalPlaces,
                )
              : ""
          }
          selected={props.selected}
          onSelect={props.onSelect}
          onDeselect={props.onDeselect}
          onChange={onChange}
          label={props.label}
          textAlign={"right"}
          iconName={"dollar-sign"}
          className={props.className}
          disabled={props.disabled}
        />
      </div>
    );
  },
);

export default DollarInput;
