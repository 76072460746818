import { Disclosure } from "@headlessui/react";
import React from "react";
import moment from "moment/moment";
import { filter, map, pipe } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CommercialIndustrial } from "@pricing-tool/lib/products/ci/core";
import { CommercialRealEstate } from "@pricing-tool/lib/products/cre/core";

import TitledPanel from "../TitledPanel";
import DollarInput from "../DollarInput";
import PercentInput from "../PercentInput";
import InputWithIcon from "../InputWithIcon";
import CurrencyAmount from "../CurrencyAmount";
import IncomeStatementTable from "../IncomeStatement";

import "./styles.scss";
import { IndexRate } from "@pricing-tool/lib/products/ci/core/IndexRates";
import { displayRateType } from "@pricing-tool/lib/products/ci/model/AmortizationModel";
import { INDEX_NAMES, INDEXES } from "@/src/components/common/LoanForm";

type LoanViewerProps = {
  product: CommercialIndustrial | CommercialRealEstate;
};

const LoanViewer = ({ product }: LoanViewerProps) => {
  return (
    <div className="component loan-viewer">
      <div className="two-column">
        <div className="col">
          <TitledPanel title="Inputs">
            <table className="inputs-table">
              <tbody>
                <tr>
                  <td>Loan Amount:</td>
                  <td>
                    <DollarInput
                      value={product.loanAmount}
                      onChange={(value) => {}}
                      disabled={true}
                      decimalPlaces={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Rate Type:</td>
                  <td>
                    <select
                      className="rounded-md shadow-sm p-2"
                      value={
                        product.payments?.rateType &&
                        displayRateType(product.payments?.rateType)
                      }
                      onChange={(event) => {}}
                      disabled={true}
                    >
                      <option value="none">-</option>
                      <option value="Fixed">Fixed</option>
                      <option value="Floating">Floating</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>Index:</td>
                  <td>
                    <div className="flex text-nowrap">
                      <select
                        className="index rounded-md shadow-sm p-2"
                        value={product.interest?.index}
                        onChange={(event) => {}}
                        disabled={true}
                      >
                        <option value="none">-</option>
                        {pipe(
                          filter(({ index }) => INDEXES.includes(index)), // TODO - support more
                          map(({ index, date, rate }: IndexRate) => (
                            <option key={index} id={index} value={index}>
                              [{index}] {rate.toFormat(5)}% (
                              {moment(date).format("LL")}) -{" "}
                              {INDEX_NAMES[index]}
                            </option>
                          )),
                        )(product?.indexRatesSnapshot?.indexRates || [])}
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Spread:</td>
                  <td>
                    <PercentInput
                      dp={5}
                      value={product.interest?.spread}
                      onChange={(value) => {}}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Interest Rate:</td>
                  <td>
                    <PercentInput
                      dp={5}
                      disabled={true}
                      onChange={() => {}}
                      value={product.interest?.interestRate}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Payment Type:</td>
                  <td>
                    <select
                      className="rounded-md shadow-sm p-2"
                      value={product.payments?.paymentType}
                      onChange={(event) => {}}
                      disabled={true}
                    >
                      <option value="none">-</option>
                      <option value="mortgage_amortization">
                        Mortgage Amortization
                      </option>
                      {/*<option value="straight_line">Straight Line</option>*/}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Payment Frequency:</td>
                  <td>
                    <select
                      className="rounded-md shadow-sm p-2"
                      value={product.payments?.paymentFrequency}
                      onChange={(event) => {}}
                      disabled={true}
                    >
                      <option value="none">-</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Term (years):</td>
                  <td>
                    <InputWithIcon
                      value={
                        product.payments?.termYears
                          ? `${product.payments.termYears}`
                          : ""
                      }
                      onChange={(value) => {}}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Amortization (years):</td>
                  <td>
                    <InputWithIcon
                      value={
                        product.payments?.amortizationYears
                          ? `${product.payments.amortizationYears}`
                          : ""
                      }
                      onChange={(value) => {}}
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Collateral:</td>
                  <td>
                    <div className="flex w-full">
                      <CurrencyAmount
                        amount={product.collateral?.effectiveValue}
                        decimalPlaces={0}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </TitledPanel>
        </div>

        <div className="col">
          <TitledPanel title="Outputs">
            <IncomeStatementTable
              incomeStatement={product.incomeStatement}
              feeOrInterest="interest"
            />
          </TitledPanel>
        </div>
      </div>

      <div className="mx-auto w-full">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`w-full flex justify-start items-center p-2 bg-blue-100 ${
                  open ? "rounded-t-md" : "rounded-md"
                }`}
              >
                <span className="inline-block w-6 text-sm">
                  <FontAwesomeIcon
                    icon={open ? "chevron-down" : "chevron-right"}
                  />
                </span>
                <span>Amortization Summary</span>
              </Disclosure.Button>
              <Disclosure.Panel className="p-2 pl-8 border-2 border-solid border-blue-100 rounded-b-md">
                <table className="inputs-table">
                  <tbody>
                    <tr>
                      <td>Effective Interest Rate:</td>
                      <td>
                        {product.payments?.amortizationSummary?.effectiveInterestRate
                          .mul(100)
                          .toFormat(2)}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Number of Payments:</td>
                      <td>
                        {
                          product.payments?.amortizationSummary
                            ?.numberOfPayments
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Total Paid:</td>
                      <td>
                        $
                        {product.payments?.amortizationSummary?.totalPaid.toFormat(
                          2,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Interest Paid:</td>
                      <td>
                        $
                        {product.payments?.amortizationSummary?.interestPaid.toFormat(
                          2,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Balloon Payments:</td>
                      <td>
                        $
                        {product.payments?.amortizationSummary?.balloonPaid.toFormat(
                          2,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Average Balance:</td>
                      <td>
                        $
                        {product.payments?.amortizationSummary?.averageBalance.toFormat(
                          2,
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <div className="mx-auto my-4 w-full">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`w-full flex justify-start items-center p-2 bg-blue-100 ${
                  open ? "rounded-t-md" : "rounded-md"
                }`}
              >
                <span className="inline-block w-6 text-sm">
                  <FontAwesomeIcon
                    icon={open ? "chevron-down" : "chevron-right"}
                  />
                </span>
                <span>Amortization Schedule</span>
              </Disclosure.Button>
              <Disclosure.Panel className="p-2 pl-8 border-2 border-solid border-blue-100 rounded-b-md">
                <table className="w-full">
                  <thead>
                    <tr>
                      <td>Date</td>
                      <td>Days Since Last</td>
                      <td>Interest Rate</td>
                      <td>Beginning Balance</td>
                      <td>Payment</td>
                      <td>Interest</td>
                      <td>Principal</td>
                      <td>Ending Balance</td>
                    </tr>
                  </thead>
                  <tbody>
                    {map(
                      (payment) => (
                        <tr key={`payment-${payment.date}`}>
                          <td>{moment(payment.date).format("LL")}</td>
                          <td>{payment.daysSinceLastPayment}</td>
                          <td>{payment.interestRate.mul(100).toFormat(2)}%</td>
                          <td>${payment.beginningBalance.toFormat(2)}</td>
                          <td>${payment.payment.toFormat(2)}</td>
                          <td>${payment.interest.toFormat(2)}</td>
                          <td>${payment.principal.toFormat(2)}</td>
                          <td>${payment.endingBalance.toFormat(2)}</td>
                        </tr>
                      ),
                      product.payments?.amortizationSchedule || [],
                    )}
                  </tbody>
                </table>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      {/*<pre className="text-gray-300">*/}
      {/*  {commercialIndustrial && JSON.stringify(commercialIndustrial, null, 2)}*/}
      {/*</pre>*/}
    </div>
  );
};

export default LoanViewer;
