import { Schema as S } from "@effect/schema";
import { ScenarioInput } from "@pricing-tool/graphql/lib/react";

import { Scenario, ScenarioSchema } from "./core";

import { toDto as toIncomeStatementDto } from "../incomeStatement/dto";

import { toDto as toProductsDto } from "../products/dto";

export type ScenarioDto = S.Schema.Encoded<typeof ScenarioSchema>;

export const fromDto = (dto: ScenarioDto): Scenario => {
  return S.decodeUnknownSync(ScenarioSchema)(dto);
};

export const toDto = (
  scenario: Scenario,
  opportunityId: string,
): ScenarioDto => {
  return S.encodeSync(ScenarioSchema)({
    ...scenario,
    opportunityId,
  });
};

export const toInput = (
  scenario: Scenario,
  opportunityId: string,
): ScenarioInput => {
  const prunedScenario = { ...scenario };
  delete prunedScenario.isDirty;

  return {
    ...prunedScenario,
    opportunityId,
    // @ts-ignore - FIXME
    products: toProductsDto(scenario.products),
    incomeStatement: scenario.incomeStatement
      ? toIncomeStatementDto(scenario.incomeStatement)
      : undefined,
  };
};
