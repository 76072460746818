import { useOutletContext } from "react-router";

import { Scenario } from "@pricing-tool/lib/scenario/core";
import { ProductType } from "@pricing-tool/lib/products/core";
import ScenarioViewer from "@/src/components/ScenarioViewer";

type ContextType = {
  scenario: Scenario;
  onSelectProduct: (scenarioId: string, productType: ProductType) => void;
  selectedProduct: ProductType;
};

function ScenarioOutlet() {
  const { scenario, onSelectProduct, selectedProduct } =
    useOutletContext<ContextType>();

  if (!scenario) return <></>;

  console.log(`rendering scenario outlet with ${scenario.id}`);

  return (
    <ScenarioViewer
      scenario={scenario}
      onSelectProduct={onSelectProduct}
      selectedProduct={selectedProduct}
    />
  );
}

export default ScenarioOutlet;
