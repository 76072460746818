import React from "react";
import { useParams } from "react-router";
import Decimal from "decimal.js-light";

import { useGetOpportunityVersionQuery } from "@pricing-tool/graphql/lib/react";

import LoadingOverlay from "../../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../../components/common/ErrorBanner";
import { fromDto } from "@pricing-tool/lib/opportunityVersion/dto";
import { OpportunityVersion } from "@pricing-tool/lib/opportunityVersion/core";
import OpportunityViewer from "@/src/components/OpportunityViewer";

// FIXME - don't hardcode
const performanceTargets = {
  returnOnEquity: new Decimal("0.1500"),
  returnOnAssets: new Decimal("0.0100"),
};

function ViewOpportunityVersion() {
  const { opportunityId, opportunityVersionId } = useParams();

  const [data, setData] = React.useState<OpportunityVersion>();

  const { loading, error } = useGetOpportunityVersionQuery({
    variables: {
      branchId: "branch-1",
      opportunityId: opportunityId!,
      id: opportunityVersionId!,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setData(fromDto(data.getOpportunityVersion));
    },
  });

  return (
    <>
      {loading && <LoadingOverlay text="Loading version" />}
      {error && <ErrorBanner text={error.message} />}

      {data?.opportunity && (
        <OpportunityViewer
          opportunity={data.opportunity}
          performanceTargets={performanceTargets}
        />
      )}
      {/*<pre className="text-gray-300">*/}
      {/*  {data?.opportunity && JSON.stringify(data.opportunity, null, 2)}*/}
      {/*</pre>*/}
    </>
  );
}

export default ViewOpportunityVersion;
