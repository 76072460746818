import React from "react";

import Modal, { ModalFooter } from "../../../common/Modal";
import { Button } from "@/components/ui/button";
import {
  File,
  FileChartColumn,
  GalleryVerticalEnd,
  Save,
  Trash,
} from "lucide-react";
import OpportunityVersionCommentModal from "@/src/components/opportunity/modals/OpportunityVersionCommentModal";

type OpportunityActionsProps = {
  saveButtonDisabled: boolean;
  onSaveOpportunity?: (comment?: string) => void;
  onDeleteOpportunity?: () => void;
  onShowIncomeStatement?: () => void;
  onShowDocuments?: () => void;
  onShowVersionHistoryModal?: () => void;
};

const Component = (props: OpportunityActionsProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const [showCommentModal, setShowCommentModal] = React.useState(false);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          {props.onSaveOpportunity && (
            <Button
              disabled={props.saveButtonDisabled}
              onClick={() => setShowCommentModal(true)}
            >
              <Save />
              Save
            </Button>
          )}
          {props.onDeleteOpportunity && (
            <Button
              variant="ghost"
              className="text-red-500 hover:text-red-500 hover:border-red-500"
              size="icon"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              <Trash />
            </Button>
          )}
        </div>
        <div className="flex items-center gap-4">
          {props.onShowIncomeStatement && (
            <Button variant="outline" onClick={props.onShowIncomeStatement}>
              <FileChartColumn />
              Income Statement
            </Button>
          )}
          {props.onShowDocuments && (
            <Button variant="outline" onClick={props.onShowDocuments}>
              <File />
              Documents
            </Button>
          )}
          {props.onShowVersionHistoryModal && (
            <Button variant="outline" onClick={props.onShowVersionHistoryModal}>
              <GalleryVerticalEnd />
              History
            </Button>
          )}
        </div>
      </div>

      {showDeleteConfirmation && (
        <Modal
          title="Delete Opportunity"
          width={400}
          contentHeight={100}
          onClose={() => setShowDeleteConfirmation(false)}
        >
          <div className="confirm-remove-elements-form">
            <div className="body overflow-auto">
              <div className="m-3">
                <p className="mb-2">
                  Are you sure you want to delete this opportunity? This action
                  cannot be undone.
                </p>
              </div>
            </div>
            <ModalFooter className="flex gap-4 justify-end">
              <Button
                variant="secondary"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteConfirmation(false);
                  props.onDeleteOpportunity();
                }}
              >
                Yes
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      )}

      {showCommentModal && (
        <OpportunityVersionCommentModal
          onClose={() => setShowCommentModal(false)}
          onConfirm={(comment) => {
            setShowCommentModal(false);
            props.onSaveOpportunity();
          }}
        />
      )}
    </>
  );
};

export default Component;
