import { Schema as S } from "@effect/schema";
import { map } from "ramda";

import { OpportunityInput } from "@pricing-tool/graphql/lib/react";

import { Opportunity, OpportunitySchema } from "./core";

import { toDto as toIncomeStatementDto } from "../incomeStatement/dto";
import { toInput as toScenarioInput } from "../scenario/dto";

export type OpportunityDto = S.Schema.Encoded<typeof OpportunitySchema>;

export const fromDto = (dto: OpportunityDto): Opportunity => {
  return S.decodeUnknownSync(OpportunitySchema)(dto);
};

export const toDto = (opportunity: Opportunity): OpportunityDto => {
  return S.encodeSync(OpportunitySchema)(opportunity);
};

export const toInput = (opportunity: Opportunity): OpportunityInput => {
  const prunedOpportunity = { ...opportunity };
  delete prunedOpportunity.client;
  delete prunedOpportunity.isDirty;

  return {
    ...prunedOpportunity,
    scenarios: map(
      (scenario) => toScenarioInput(scenario, opportunity.id),
      opportunity.scenarios,
    ),
    incomeStatement: opportunity.incomeStatement
      ? toIncomeStatementDto(opportunity.incomeStatement)
      : undefined,
  };
};
