import React from "react";
import LoanViewer from "@/src/components/common/LoanViewer";
import { CommercialIndustrial } from "@pricing-tool/lib/products/ci/core";

export type CommercialIndustrialFormProps = {
  commercialIndustrial: CommercialIndustrial;
};

const CommercialIndustrialViewer = ({
  commercialIndustrial,
}: CommercialIndustrialFormProps) => {
  if (!commercialIndustrial) {
    return <></>;
  }

  return <LoanViewer product={commercialIndustrial} />;
};

export default CommercialIndustrialViewer;
