import { Schema as S } from "@effect/schema";

import NullToUndefined from "../util/NullToUndefined";
import { OpportunitySchema } from "../opportunity/core";

export const OpportunityVersionSchema = S.Struct({
  id: S.String,
  branchId: S.String,
  opportunityId: S.String,
  updatedAt: S.String,
  updatedBy: S.String,
  annotation: S.optional(NullToUndefined(S.String)),
  opportunity: S.optional(S.mutable(OpportunitySchema)),
});

export type OpportunityVersion = S.Schema.Type<typeof OpportunityVersionSchema>;
