import { Schema as S } from "@effect/schema";

import NullToUndefined from "../util/NullToUndefined";
import { OpportunitySummarySchema } from "../opportunitySummary/core";

export const OpportunityVersionSummarySchema = S.Struct({
  id: S.String,
  branchId: S.String,
  opportunityId: S.String,
  updatedAt: S.String,
  updatedBy: S.String,
  annotation: S.optional(NullToUndefined(S.String)),
  opportunity: S.optional(S.mutable(OpportunitySummarySchema)),
});

export type OpportunityVersionSummary = S.Schema.Type<
  typeof OpportunityVersionSummarySchema
>;
